import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'

import { Box } from '../components/Core'

import PageWrapper from '../components/PageWrapper'
import InvoicePage from '../components/InvoicePage/InvoicePage'

import Logo from '../components/Logo'

const BoxStyled = styled(Box)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Footer = styled(Box)`
  justify-content: flex-end;
`

const SocialList = styled.ul`
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 100%;
  padding: 0;
  a {
    display: inline-block;
    color: ${({ theme }) => theme.colors.dark} !important;
    font-size: 23px;
    transform: translateY(0);

    padding: 0 3px;
    margin: 0 12px;
    transition: all 0.4s ease-out;
    &:hover {
      transform: translateY(-3px);
      color: ${({ theme }) => theme.colors.secondary} !important;
    }
    &:visited {
      text-decoration: none;
    }
  }
`

const Design = () => {
  return (
    <>
      <PageWrapper>
        <BoxStyled>
          <Box>
            <Container className="text-center">
              <Row className="justify-content-center">
                <Col lg="10" xl="8">
                  <Box className="text-center" pt="40px">
                    <Logo />
                  </Box>
                </Col>
              </Row>
            </Container>
          </Box>
          <Box>
            <Container className="text-center">
              <Row className="justify-content-center">
                <Col lg="8" className="mt-5 mb-5">
                  <InvoicePage />
                </Col>
              </Row>
            </Container>
          </Box>

          <Footer pb="70px">
            <Container>
              <SocialList>
                <li>
                  <a href="/" target="_blank">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="/" target="_blank">
                    <i className="fab fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="/" target="_blank">
                    <i className="fab fa-google-plus-square"></i>
                  </a>
                </li>
                <li>
                  <a href="/" target="_blank">
                    <i className="fab fa-linkedin"></i>
                  </a>
                </li>
              </SocialList>
            </Container>
          </Footer>
        </BoxStyled>
      </PageWrapper>
    </>
  )
}
export default Design
